import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ErrorIcon from '@material-ui/icons/Error';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import { ButtonGroup } from '@material-ui/core';
import moment from 'moment';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { useMutation } from '@tanstack/react-query';
import LoadingSection from '../../components/LoadingComponents/LoadingSection';
import * as companyServices from '../../services/companiesServices';
import { useToast, usePitchbookingUser, usePusher } from '../../hooks';
import { requestFacilitiesRetrieval } from '../../reducers/facilitiesReducer';
import { AddDeviceDialog } from './components/AddDeviceDialog';
import EditDeviceNodeDialog from './components/EditDeviceNodeDialog';
import { DeleteDeviceDialog } from './components/DeleteDeviceDialog';
import { AlertToolTip } from '../../components/AlertToolTip';
import { GenerateKeycodeDialog } from './components/GenerateKeycodeDialog';

const LockConfiguration = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const [locks, setLocks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cancellingOverride, setCancellingOverride] = useState([]);
  const { isPitchbookingManager, isSuperAdmin } = usePitchbookingUser();
  const { companyInfo: company } = useSelector((state) => (state.companies));

  const [generateKeycodeDialog, setGenerateKeycodeDialog] = useState({
    isOpen: false,
    nodeId: null,
  });

  const { mutate: initialiseLocksMutate, isLoading: isInitialisingLocks } = useMutation({
    mutationFn: async () => companyServices.initialiseLocks(company.id),
    onSuccess: () => {
      toast.trigger({
        type: 'success',
        message: 'Locks successfully initialised.',
      });
    },
    onError: (error) => {
      console.log('There has been an error initialising locks');
      console.log(error);
      toast.trigger({
        type: 'error',
        message: 'An error occurred while attempting to initialise your locks.',
      });
    },
  });

  const fetchDevices = async () => {
    try {
      const res = await companyServices.getDevices(company.id, { type: 'LOCK' });
      setLocks(res.data);
      setIsLoading(false);
    } catch (err) {
      toast.trigger({
        type: 'error',
        message: 'An error occurred while attempting to handle your request. Please try again, if the issues persists please contact us',
      });
      console.error(err);
    }
  };

  const cancelOverride = async (node) => {
    setCancellingOverride((prev) => [...prev, node.id]);
    try {
      const res = await companyServices.updateDeviceNode(
        company.id,
        node.id,
        {
          overrideExpiry: null,
        },
      );

      if (res.error) throw new Error(res.error);

      await fetchDevices();
      toast.trigger({
        type: 'success',
        message: 'Override successfully cancelled.',
      });
    } catch (err) {
      console.error(err);

      toast.trigger({
        type: 'error',
        message: 'An error occurred while attempting to cancel your override. Please try again.',
      });
    }

    setCancellingOverride((prev) => prev.filter((x) => x !== node.id));
  };

  useEffect(() => {
    if (company.id) {
      setIsLoading(true);
      dispatch(requestFacilitiesRetrieval());
      fetchDevices();
    }
  }, [company.id]);

  usePusher('nodes', company.id, (data) => {
    setLocks((prev) => {
      if (prev.length > 0) {
        return prev.map((x) => {
          if (x.id === data.id) {
            if (data.device) {
              return {
                ...x,
                device: {
                  ...x.device,
                  ...data.device,
                },
              };
            }

            return {
              ...x,
              ...data,
            };
          }
          return x;
        });
      }
      return prev;
    });
  }, [company.id]);

  if (isLoading) {
    return (<LoadingSection loadingText="Loading please wait..." />);
  }

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', gap: '1rem',
    }}
    >
      <div>
        <Typography variant="h6">Smart Lock Configuration</Typography>

        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <div>
            <AlertToolTip
              label="Help"
              labelIconType="help"
              alertTitle="Welcome to the locks tab of your online dashboard for managing sports facilities."
              contentfulName="locksTabToolTip"
              alertSeverity="info"
            />

          </div>
          <div>
            <AlertToolTip
              label="Getting Started"
              labelIconType="info"
              alertTitle="Welcome to the locks tab of your online dashboard for managing sports facilities."
              contentfulName="locksTabGettingStartedToolTip"
              alertSeverity="info"
            />
          </div>
        </div>

        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <AddDeviceDialog
            type="lock"
            onSuccess={fetchDevices}
          />

          {isPitchbookingManager && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={initialiseLocksMutate}
              disabled={isInitialisingLocks}
            >
              Initialise Locks
            </Button>
          )}
        </div>
      </div>

      <AlternativelyVisible condition={locks.length > 0}>
        <>
          {locks.map((node) => (
            <Card
              key={node.id}
            >
              <CardContent>
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                >
                  <div>
                    <Typography variant="h5">
                      {node.friendlyName}
                    </Typography>
                    <Typography>{`External ID: ${node.nodeIdentifier}`}</Typography>
                    <Typography>
                      {`Facilities: ${node.facilities.length > 0 ? node.facilities.map((x) => x.name).join(', ') : 'None'}`}
                    </Typography>
                    <ConditionallyVisible condition={node.isSmart}>
                      {node.device.isOnline ? (
                        <>
                          <Typography variant="h6" color={node.state === 'OFF' ? 'error' : 'primary'} style={{ fontWeight: 600 }}>
                            {node.state}
                          </Typography>
                          <Typography>{`Last Health Check: ${moment(node.device.lastHealthCheck).format('LLL')}`}</Typography>
                        </>
                      ) : (
                        <>
                          <Typography color="error">Device Offline</Typography>
                        </>
                      )}
                    </ConditionallyVisible>
                  </div>
                  {node.device.actions?.length > 0 && (
                    <div>
                      <ButtonGroup
                        variant="contained"
                        color="secondary"
                        size="small"
                      >
                        {node.device.actions.map((x) => {
                          if (x === 'HEALTH_CHECK') {
                            if (isPitchbookingManager) {
                              return (
                                <Button
                                  onClick={() => companyServices.sendNodeCommand(
                                    company.id,
                                    node.id,
                                  )}
                                >
                                  Health Check
                                </Button>
                              );
                            }

                            return null;
                          }

                          let callback = () => companyServices.sendNodeCommand(
                            company.id, node.id, x,
                          );

                          if (x === 'GENERATE_KEYCODE') {
                            callback = async () => {
                              setGenerateKeycodeDialog({
                                isOpen: true,
                                nodeId: node.id,
                              });
                            };
                          }

                          return (
                            <Button
                              onClick={callback}
                            >
                              {x.replace('_', ' ')}
                            </Button>
                          );
                        })}
                      </ButtonGroup>
                    </div>
                  )}
                </div>
                <div style={{
                  display: 'flex', gap: '1rem', marginTop: '1rem',
                }}
                >
                  <EditDeviceNodeDialog
                    node={node}
                    onSuccess={fetchDevices}
                    scheduleTypeOptions={[]}
                  />

                  {(isPitchbookingManager || isSuperAdmin) && (
                    <DeleteDeviceDialog
                      node={node}
                      onSuccessCallback={fetchDevices}
                    />
                  )}
                  <ConditionallyVisible condition={node.overrideExpiry}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => cancelOverride(node)}
                      disabled={cancellingOverride.includes(node.id)}
                    >
                      Cancel Override
                    </Button>
                  </ConditionallyVisible>
                </div>
              </CardContent>
            </Card>
          ))}
        </>

        <>
          <Card>
            <CardContent style={{
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            }}
            >
              <ErrorIcon style={{ fontSize: '2rem' }} />
              <Typography variant="subheading">
                You don&apos;t have any lock controls configured.
                Please contact your account manager for more information.
              </Typography>
            </CardContent>
          </Card>
        </>
      </AlternativelyVisible>

      <GenerateKeycodeDialog
        isOpen={generateKeycodeDialog.isOpen}
        onClose={() => setGenerateKeycodeDialog({
          isOpen: false,
          nodeId: null,
        })}
        nodeId={generateKeycodeDialog.nodeId}
      />
    </div>
  );
};

export default LockConfiguration;
