/* eslint-disable no-unreachable */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, Button,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import * as Yup from 'yup';
import { useMobile, useCompany, useToast } from '../../../hooks';
import { sendNodeCommand } from '../../../services/companiesServices';

const validationSchema = Yup.object().shape({
  startTime: Yup.string().required('Start time is required'),
  endTime: Yup.string().required('End time is required'),
});

export const GenerateKeycodeDialog = ({ isOpen, onClose, nodeId }) => {
  const isMobile = useMobile();
  const { id: companyId } = useCompany();
  const toast = useToast();

  const [initialValues, setInitialValues] = useState({
    startTime: '',
    endTime: '',
  });

  const mutate = useMutation({
    mutationFn: async (values) => {
      let { startTime, endTime } = values;
      startTime = moment(startTime, 'HH:mm');
      endTime = moment(endTime, 'HH:mm');

      if (endTime.isBefore(startTime)) {
        endTime = endTime.add(1, 'day');
      }

      const { data } = await sendNodeCommand(
        companyId, nodeId, 'GENERATE_KEYCODE', {
          startTime: startTime.format(),
          endTime: endTime.format(),
        },
      );

      if (data) {
        // eslint-disable-next-line no-restricted-globals
        confirm(`Generated keycode: ${data.keycode}`);
      }

      onClose();
    },
    onError: () => {
      toast.trigger({
        message: 'Failed to generate keycode',
        type: 'error',
      });
    },
  });

  useEffect(() => {
    if (isOpen) {
      setInitialValues({
        startTime: moment().format('HH:mm'),
        endTime: moment().add(1, 'h').format('HH:mm'),
      });
    } else {
      setInitialValues({
        startTime: '',
        endTime: '',
      });
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={mutate.isLoading ? () => {} : onClose}
      fullScreen={isMobile}
      fullWidth
    >
      <DialogTitle>Generate Keycode</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={mutate.mutate}
          validationSchema={validationSchema}
        >
          {({
            getFieldProps, errors, touched,
          }) => (
            <Form style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                <PBInput
                  label="Start Time"
                  type="time"
                  isError={errors.startTime && touched.startTime}
                  errorMessage={touched.startTime && errors.startTime}
                  {...getFieldProps('startTime')}
                />

                {touched.startTime && errors.startTime && (
                <div style={{ color: 'red' }}>{errors.startTime}</div>
                )}
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                <PBInput
                  label="End Time"
                  type="time"
                  isError={errors.endTime && touched.endTime}
                  errorMessage={touched.endTime && errors.endTime}
                  {...getFieldProps('endTime')}
                />

                {touched.endTime && errors.endTime && (
                <div style={{ color: 'red' }}>{errors.endTime}</div>
                )}
              </div>

              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={mutate.isLoading}
                  type="submit"
                >
                  Generate Keycode
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
