import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { Typography } from '@material-ui/core';
import ActionBar from '../../components/ActionBar';
import RunDoubleBookingCheckButton from '../../components/RunDoubleBookingCheckButton';
import RunCronJobSelectorAndButton from '../../components/RunCronJobSelectorAndButton';
import CreateCompanyDialog from '../../components/CreateCompanyDialog';
import ReauthoriseXeroButton from '../../components/ReauthoriseXeroButton';
import PitchbookingUserOnly from '../../helpers/PitchbookingUserOnly';
import * as actions from '../../reducers/utilsReducer';
import { refreshLeagues } from '../../services/utilsServices';
import CreateNewDemoDialog from '../createDemo/CreateNewDemoDialog';
import { ValidateMembershipSubscriptionsButton } from './ValidateMembershipSubscriptionsButton';
import { RunAll, RunCriticalOnly } from '../../components/DbHealthCheckButtons';

class Utils extends React.Component {
  componentDidMount() {
    const { doRequestXeroExchangeCode } = this.props;
    const url = window.location.href;
    if (url.includes('xero-auth')) {
      doRequestXeroExchangeCode(url);
    }
  }

  render() {
    const { companies } = this.props;
    const { id } = companies.companyInfo;
    const localOrDemo = window.location.href.includes('manager-demo.pitchbooking.co') || window.location.href.includes('localhost:4999');

    return (
      <>
        <PitchbookingUserOnly>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#fff',
            margin: '1rem',
            padding: '1rem',
            border: '1px solid #e0e0e0',
            borderRadius: '0.75rem',
            width: 'fit-content',
            gap: '1rem',
          }}
          >
            {/* DB Health Check's */}
            <>
              <Typography variant="body1" style={{ fontWeight: 600, textDecoration: 'underline' }}>
                DB Health Check Actions
              </Typography>
              <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                <RunAll />
                <RunCriticalOnly />
              </div>
            </>

            {/* Other Actions */}
            <>
              <Typography variant="body1" style={{ fontWeight: 600, textDecoration: 'underline' }}>
                Other Actions
              </Typography>
              <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                <RunDoubleBookingCheckButton />
                <CreateCompanyDialog />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => refreshLeagues(id)}
                >
                  Refresh Leagues
                </Button>
              </div>
            </>
          </div>

          <ActionBar>
            <RunCronJobSelectorAndButton />
          </ActionBar>
          <ActionBar>
            <ReauthoriseXeroButton />
            <ValidateMembershipSubscriptionsButton />
          </ActionBar>
        </PitchbookingUserOnly>
        <ConditionallyVisible condition={localOrDemo}>
          <ActionBar>
            <CreateNewDemoDialog />
          </ActionBar>
        </ConditionallyVisible>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users, companies } = state;
  const { isLoading } = users;
  return {
    users, isLoading, companies,
  };
}

Utils.propTypes = {
  doRequestXeroExchangeCode: PropTypes.func.isRequired,
  companies: PropTypes.shape().isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  doRequestXeroExchangeCode: (code) => dispatch(actions.requestXeroExchangeCode(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Utils);
