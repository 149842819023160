// @flow weak

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { runDoubleBookingCheck } from '../reducers/companiesReducer';

// eslint-disable-next-line react/prefer-stateless-function
class RunDoubleBookingCheckButton extends Component {
  render() {
    const { RunDoubleBookingCheck } = this.props;
    return (
      <Button
        onClick={() => RunDoubleBookingCheck()}
        color="secondary"
        variant="contained"
      >
        Run Double Booking Check
      </Button>
    );
  }
}

RunDoubleBookingCheckButton.propTypes = {
  RunDoubleBookingCheck: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  RunDoubleBookingCheck: () => dispatch(runDoubleBookingCheck()),
});

export default connect(null, mapDispatchToProps)(RunDoubleBookingCheckButton);
