import {
  takeEvery, call, put, select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import {
  getCompanyMembershipsService, updateCompanyServices, createCompanyMembershipService,
  changeCompanyPaymentPreferenceServices, getCompanyInfoServices, authoriseStripeService,
  retrieveBillingService, createStripeBillingService, getCompanyInvoicesService,
  getInvoiceInformationService, createCompanyInvoicesService, recordPaymentInvoicesService,
  voidInvoiceService, updateInvoiceInstructionsService, resendInvoiceService, updateInvoiceService,
  runDbHealthCheckService, runDoubleBookingCheckService,
  updateCompanyDetailsService, createCompanyService,
  setBillingAsProcessingService, getCompanyUsersService, updateBillingService,
  updateCompanyProductsService, createBillingService, companiesPayLaterService,
  updateCompanyServiceFeesServices,
} from '../services/companiesServices';
import * as actions from '../reducers/companiesReducer';
import * as invoiceActions from '../reducers/invoicesReducer';
import * as tagsActions from '../reducers/tagsReducer';
import { getEvent } from '../reducers/eventsReducer';
import { handleSagaError } from './helperSaga';

function* updateCompanySaga(companiesAction) {
  const { companyId, reqBody } = companiesAction;
  try {
    const response = yield call(
      updateCompanyServices,
      companyId,
      reqBody,
    );
    if (!response.error) {
      yield put(actions.getCompanyInfo());
      yield put(sessionActions.updateSuccessfulSnackbar('Your company info has been updated successfully!', true));
    } else {
      yield put(sessionActions.updateErrorSnackbar('Company Update Failed.', true));
      throw response;
    }
  } catch (error) {
    yield call(handleSagaError, error, 'updateCompanySaga');
  }
}

function* updateCompanyDetailsSaga(companyId, companiesAction) {
  try {
    const response = yield call(
      updateCompanyDetailsService,
      companyId,
      companiesAction.reqBody,
    );
    if (!response.error) {
      yield put(actions.requestCompanyMembershipRetrieval());
      yield put(actions.getCompanyInfo());
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'updateCompanyDetailsSaga');
  }
}

function* getCompanyInfoSaga(companyId, action) {
  try {
    const { onboarding, showAccountOwner } = action;
    const response = yield call(getCompanyInfoServices, companyId, onboarding, showAccountOwner);
    if (!response.error) {
      yield put(actions.updateCompanyInfo(response.data));
      yield put(tagsActions.setCompanyTags(response.data.tags));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getCompanyInfoSaga');
    yield put(sessionActions.updateErrorSnackbar(`Company Retrieval Failed : ${error}`, true));
  }
}

function* changePaymentPreferenceSaga(companyId, action) {
  try {
    const reqBody = action.preference;
    const response = yield call(
      changeCompanyPaymentPreferenceServices,
      companyId,
      reqBody,
    );
    if (!response.error) {
      yield put(actions.updateCompanyInfo(response.data));
      yield put(sessionActions.updateSuccessfulSnackbar('Your company info has been updated successfully!', true));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'changePaymentPreferenceSaga');
  }
}

// Memberships
function* getCompanyMembershipSaga(companyId, action) {
  try {
    const type = action.membershipType;
    const response = yield call(getCompanyMembershipsService, companyId, type);
    if (!response.error) {
      if (response.some((membership) => membership.type === 'TAX_EXEMPT')) {
        yield put(actions.succeedCompanyTaxExemptMembershipRetrieval(response));
      } else {
        yield put(actions.succeedCompanyMembershipRetrieval(response));
      }
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getCompanyMembershipSaga');
  }
}

function* getCompanyInvoicesSaga(companyId, action) {
  const { status, selectedMonth } = action;
  try {
    const response = yield call(
      getCompanyInvoicesService, companyId, status, selectedMonth,
    );
    if (!response.error) {
      yield put(invoiceActions.succeedCompanyInvoicesRetrieval(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getCompanyInvoicesSaga');
    yield put(invoiceActions.failCompanyInvoicesRetrieval());
  }
}

function* getInvoiceInformationSaga(companyId, action) {
  const { invoiceId } = action;
  try {
    const response = yield call(getInvoiceInformationService, companyId, invoiceId);
    if (!response.error) {
      yield put(invoiceActions.succeedInvoiceInformationRetrieval(response.data[0]));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getInvoiceInformationSaga');
    yield put(invoiceActions.failInvoiceInformationRetrieval());
  }
}

function* calculateInvoiceTotalSaga() {
  yield put(invoiceActions.updateCreateInvoiceSelectedReservations(null));
}

function* resendInvoiceSaga(companyId, action) {
  const { invoiceId, userIds } = action;
  const reqBody = { userIds };
  try {
    const response = yield call(resendInvoiceService, companyId, invoiceId, reqBody);
    if (!response.error) {
      yield put(invoiceActions.succeedResendInvoice());
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'resendInvoiceSaga');
    yield put(invoiceActions.failResendInvoice());
  }
}

function* createCompanyInvoicesSaga(companyId) {
  const state = yield select();
  const { createInvoice } = state.invoices;
  const reqBody = createInvoice;
  try {
    const response = yield call(createCompanyInvoicesService, companyId, reqBody);
    if (!response.error) {
      yield put(invoiceActions.succeedCompanyInvoicesCreation(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'createCompanyInvoicesSaga');
    yield put(invoiceActions.failCompanyInvoicesCreation());
  }
}

function* voidInvoiceSaga(companyId, action) {
  const reqBody = { invoiceId: action.invoiceId };
  try {
    const response = yield call(voidInvoiceService, companyId, reqBody);
    if (!response.error) {
      yield put(invoiceActions.succeedVoidInvoice(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'voidInvoiceSaga');
    yield put(invoiceActions.failVoidInvoice());
  }
}

function* updateInvoiceInstructionsSaga(companyId, action) {
  const reqBody = { invoiceInstructions: action.instructions };
  try {
    const response = yield call(updateInvoiceInstructionsService, companyId, reqBody);
    if (!response.error) {
      yield put(actions.getCompanyInfo());
      yield put(sessionActions.updateSuccessfulSnackbar('Your company info has been updated successfully!', true));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'updateInvoiceInstructionsSaga');
    yield put(invoiceActions.failVoidInvoice());
  }
}

function* recordPaymentInvoicesSaga(companyId, action) {
  const state = yield select();
  const { recordPaymentInvoice } = state.invoices;
  const reqBody = {
    invoiceId: action.invoiceId,
    ...recordPaymentInvoice,
  };
  try {
    const response = yield call(recordPaymentInvoicesService, companyId, reqBody);
    if (!response.error) {
      yield put(invoiceActions.succeedRecordPaymentInvoice(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'recordPaymentInvoicesSaga');
    yield put(invoiceActions.failRecordPaymentInvoice());
  }
}

function* updateInvoiceSaga(companyId, action) {
  const { invoiceId, newInvoice } = action;
  const reqBody = {
    ...newInvoice,
  };
  try {
    const response = yield call(updateInvoiceService, companyId, invoiceId, reqBody);
    if (!response.error) {
      yield put(invoiceActions.succeedUpdateInvoice(response.data[0]));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'updateInvoiceSaga');
    yield put(invoiceActions.failUpdateInvoice());
  }
}

function* createCompanyMembershipSaga(companyId, companiesAction) {
  try {
    const response = yield call(
      createCompanyMembershipService,
      companyId,
      companiesAction.reqBody,
    );
    if (!response.error) {
      yield put(actions.createCompanyMembershipSuccess());
      yield put(actions.requestCompanyMembershipRetrieval());
    } else {
      yield put(actions.createCompanyMembershipFail());
      throw response;
    }
  } catch (error) {
    yield put(actions.createCompanyMembershipFail());
    yield call(handleSagaError, error, 'createCompanyMembershipSaga');
  }
}

function* authoriseStripeSaga(companyId, action) {
  try {
    const response = yield call(
      authoriseStripeService,
      companyId,
      action.stripeAuthorisationCode,
    );
    if (!response.error) {
      yield put(actions.succeedStripeAuthorisation());
    } else { throw response; }
  } catch (error) {
    yield put(actions.failStripeAuthorisation());
    yield call(handleSagaError, error, 'authoriseStripeSaga');
  }
}

// Billing
function* retrieveBillingSaga(companyId, action) {
  try {
    const response = yield call(retrieveBillingService, companyId, action.billingId);
    if (!response.error) {
      yield put(actions.billingDataRetrieved(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'retrieveBillingSaga');
  }
}

// Diagnostics
function* runDbHealthCheckSaga(companyId, action) {
  const { otherParams } = action;

  try {
    const response = yield call(runDbHealthCheckService, companyId, otherParams);
    if (!response.error) {
      yield put(actions.runDbHealthCheckSuccess());
    } else { throw response; }
  } catch (error) {
    yield put(actions.runDbHealthCheckFail());
    yield call(handleSagaError, error, 'runDbHealthCheckSaga');
  }
}

function* runDoubleBookingCheckSaga(companyId) {
  try {
    const response = yield call(runDoubleBookingCheckService, companyId);
    if (!response.error) {
      yield put(actions.runDoubleBookingCheckSuccess());
    } else { throw response; }
  } catch (error) {
    yield put(actions.runDoubleBookingCheckFail());
    yield call(handleSagaError, error, 'runDoubleBookingCheckSaga');
  }
}

function* createStripeBillingSaga(companyId, action) {
  try {
    const response = yield call(
      createStripeBillingService,
      companyId,
      action.source,
    );
    if (!response.error) {
      yield put(actions.succeedStripeBilling(response.data));
    } else { throw response; }
  } catch (error) {
    yield put(actions.failStripeBilling(error.error));
    yield call(handleSagaError, error, 'createStripeBillingSaga');
  }
}

function* setBillingAsProcessingSaga(companyId, action) {
  try {
    const response = yield call(
      setBillingAsProcessingService,
      companyId,
      action.billingId,
    );
    if (!response.error) {
      yield put(actions.succeedStripeBilling(response.data));
    } else { throw response; }
  } catch (error) {
    yield put(actions.failStripeBilling(error.error));
    yield call(handleSagaError, error, 'createStripeBillingSaga');
  }
}

function* createCompanySaga(companyId) {
  const state = yield select();
  const { newCompany } = state.companies;
  if (companyId) {
    try {
      const response = yield call(createCompanyService, companyId, newCompany);
      if (!response.error && !response.data.error) {
        const { billingLink } = response.data?.data;
        yield put(actions.createCompanySuccess(billingLink));
      } else { throw response; }
    } catch (error) {
      yield call(handleSagaError, error, 'createCompanySaga');
      const errorMessage = error?.response?.data?.data?.error;
      yield put(actions.createCompanyFail(error.error));
      yield put(sessionActions.updateErrorSnackbar(`Company Creation Failed${errorMessage ? `: ${errorMessage}.` : '.'}`, true));
    }
  }
}

function* saveCompanyProductsSaga(action) {
  const state = yield select();
  const { companyInfo } = state.companies;
  const { products } = companyInfo;
  const { companyId } = action;
  try {
    const response = yield call(updateCompanyProductsService, companyId, { products });
    if (!response.error) {
      yield put(actions.updateCompanyInfo(response.data));
      yield put(sessionActions.updateSuccessfulSnackbar('Your company info has been updated successfully!', true));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'saveCompanyProductsSaga');
  }
}

function* companyPayLaterSaga(companyId, action) {
  const { payLaterSettings } = action;

  try {
    const response = yield call(
      companiesPayLaterService,
      companyId,
      payLaterSettings,
    );
    if (!response.error) {
      yield put(actions.getCompanyInfo());
      if (payLaterSettings?.eventId) { yield put(getEvent(payLaterSettings.eventId)); }
      yield put(sessionActions.updateSuccessfulSnackbar('Your company info has been updated successfully!', true));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'companyPayLaterSaga');
  }
}

function* updateBillingSaga(companyId, action) {
  try {
    const response = yield call(
      updateBillingService,
      companyId,
      action.billingId,
      action.reqBody,
    );
    if (!response.error) {
      yield put(actions.getBillingData(action.billingId));
    } else { throw response; }
  } catch (error) {
    yield put(sessionActions.updateErrorSnackbar('Billing Update Failed.', true));
  }
}

function* updateCompanyServiceFeesSaga(companyId, action) {
  const { serviceFees } = action;
  try {
    const response = yield call(
      updateCompanyServiceFeesServices,
      companyId,
      { serviceFees },
    );
    if (!response.error) {
      yield put(actions.getCompanyInfo());
      yield put(sessionActions.updateSuccessfulSnackbar('Your company service fee has been updated successfully!', true));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'updateCompanyServiceFeesSaga');
    yield put(sessionActions.updateErrorSnackbar('Error! Your company service fee has not been updated!', true));
  }
}

function* createBillingSaga(action) {
  const state = yield select();
  const { billingParams } = action;
  const { companyInfo } = state.companies;
  const { id } = companyInfo;

  try {
    const response = yield call(
      createBillingService,
      id,
      billingParams,
    );
    if (!response.error) {
      yield put(push(`/agreement/${response.data.id}`));
    } else { throw response; }
  } catch (error) {
    yield put(actions.failStripeBilling(error.error));
    yield call(handleSagaError, error, 'createBillingSaga');
  }
}
function* getCompanyUsersSaga(action) {
  const { userId } = action;
  try {
    const response = yield call(getCompanyUsersService, userId);
    if (!response.error) {
      yield put(actions.updateCurrentUsersCompanies(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getCompanyUsersSaga');
  }
}

export function* createCompanyMembershipWatcher(companyId) {
  yield takeEvery(actions.CREATE_COMPANY_MEMBERSHIP, createCompanyMembershipSaga, companyId);
}

export function* getCompanyMembershipWatcher(companyId) {
  yield takeEvery(
    actions.COMPANY_MEMBERSHIPS_RETRIEVAL_REQUESTED,
    getCompanyMembershipSaga,
    companyId,
  );
}

export function* getCompanyInvoicesWatcher(companyId) {
  yield takeEvery(
    invoiceActions.COMPANY_INVOICES_RETRIEVAL_REQUESTED,
    getCompanyInvoicesSaga,
    companyId,
  );
}

export function* getInvoiceInformationWatcher(companyId) {
  yield takeEvery(
    invoiceActions.COMPANY_INVOICE_INFORMATION_RETRIEVAL_REQUESTED,
    getInvoiceInformationSaga,
    companyId,
  );
}

export function* resendInvoiceWatcher(companyId) {
  yield takeEvery(
    invoiceActions.RESEND_INVOICE_REQUESTED,
    resendInvoiceSaga,
    companyId,
  );
}

export function* updateInvoiceInstructionsWatcher(companyId) {
  yield takeEvery(
    invoiceActions.UPDATE_COMPANY_INVOICE_INSTRUCTIONS,
    updateInvoiceInstructionsSaga,
    companyId,
  );
}

export function* createCompanyInvoicesWatcher(companyId) {
  yield takeEvery(
    invoiceActions.COMPANY_INVOICE_CREATION_REQUESTED,
    createCompanyInvoicesSaga,
    companyId,
  );
}

export function* voidInvoiceWatcher(companyId) {
  yield takeEvery(
    invoiceActions.VOID_INVOICE_REQUESTED,
    voidInvoiceSaga,
    companyId,
  );
}

export function* recordPaymentInvoicesWatcher(companyId) {
  yield takeEvery(
    invoiceActions.COMPANY_RECORD_PAYMENT_INVOICE_REQUESTED,
    recordPaymentInvoicesSaga,
    companyId,
  );
}

export function* updateInvoiceWatcher(companyId) {
  yield takeEvery(
    invoiceActions.UPDATE_INVOICE_REQUESTED,
    updateInvoiceSaga,
    companyId,
  );
}

export function* updateInvoiceCalculationWatcher() {
  yield takeEvery(
    invoiceActions.ADD_INVOICE_ADDITIONAL_ITEM,
    calculateInvoiceTotalSaga,
  );
}

export function* getCompanyInfoWatcher(companyId) {
  yield takeEvery(actions.GET_COMPANY_INFO, getCompanyInfoSaga, companyId);
  yield takeEvery(actions.STRIPE_AUTHORISATION_SUCCEEDED, getCompanyInfoSaga, companyId);
}

export function* changePaymentPreferenceWatcher(companyId) {
  yield takeEvery(actions.CHANGE_PAYMENT_PREFERENCE, changePaymentPreferenceSaga, companyId);
}

export function* authoriseStripeWatcher(companyId) {
  yield takeEvery(actions.STRIPE_AUTHORISATION_REQUESTED, authoriseStripeSaga, companyId);
}

export function* retrieveBillingWatcher(companyId) {
  yield takeEvery(actions.GET_BILLING_DATA, retrieveBillingSaga, companyId);
}

export function* createStripeBillingWatcher(companyId) {
  yield takeEvery(actions.SET_STRIPE_BILLING, createStripeBillingSaga, companyId);
}

export function* setBillingAsProcessingWatcher(companyId) {
  yield takeEvery(actions.SET_BILLING_AS_PROCESSING, setBillingAsProcessingSaga, companyId);
}

export function* runDbHealthCheckWatcher(companyId) {
  yield takeEvery(actions.RUN_DB_HEALTH_CHECK, runDbHealthCheckSaga, companyId);
}

export function* runDoubleBookingCheckWatcher(companyId) {
  yield takeEvery(actions.RUN_DOUBLE_BOOKING_CHECK, runDoubleBookingCheckSaga, companyId);
}

export function* updateCompanyWatcher() {
  yield takeEvery(actions.UPDATE_COMPANY, updateCompanySaga);
}

export function* updateCompanyDetailsWatcher(companyId) {
  yield takeEvery(actions.UPDATE_COMPANY_DETAILS, updateCompanyDetailsSaga, companyId);
}

export function* createCompanyWatcher(companyId) {
  yield takeEvery(actions.CREATE_COMPANY_REQUESTED, createCompanySaga, companyId);
}

export function* getCompanyUsersWatcher() {
  yield takeEvery(actions.GET_CURRENT_USERS_COMPANIES, getCompanyUsersSaga);
}

export function* updateCompanyServiceFeesWatcher(companyId) {
  yield takeEvery(actions.UPDATE_SERVICE_FEES, updateCompanyServiceFeesSaga, companyId);
}

export function* updateBillingWatcher(companyId) {
  yield takeEvery(actions.UPDATE_BILLING, updateBillingSaga, companyId);
}

export function* saveCompanyProductsWatcher() {
  yield takeEvery(actions.SAVE_COMPANY_PRODUCTS, saveCompanyProductsSaga);
}

export function* createBillingWatcher() {
  yield takeEvery(actions.CREATE_BILLING, createBillingSaga);
}
export function* companiesPayLaterWatcher(companyId) {
  yield takeEvery(actions.UPDATE_COMPANY_PAY_LATER_SETTINGS, companyPayLaterSaga, companyId);
}
