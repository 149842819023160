import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { runDbHealthCheck } from '../reducers/companiesReducer';

// eslint-disable-next-line react/prefer-stateless-function
export const RunAll = () => {
  const dispatch = useDispatch();

  return (
    <Button
      onClick={() => dispatch(runDbHealthCheck({ runCriticalOnly: false }))}
      color="secondary"
      variant="contained"
    >
      Run DB Health Check
    </Button>
  );
};

export const RunCriticalOnly = () => {
  const dispatch = useDispatch();
  const [buttonBackground, setButtonBackground] = useState('#ac372f');

  return (
    <Button
      onClick={() => dispatch(runDbHealthCheck({ runCriticalOnly: true }))}
      variant="contained"
      color="destructive"
      style={{
        background: buttonBackground,
        color: '#fff',
      }}
      onMouseEnter={() => setButtonBackground('#802923')}
      onMouseLeave={() => setButtonBackground('#ac372f')}
    >
      ☢️ Run Critical DB Health Check
    </Button>
  );
};
