import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';
import axios from '../libs/axios';

export async function getCompanyMembershipsService(companyId, type) {
  let url = `/api/companies/${companyId}/memberships`;
  if (type) {
    url = `/api/companies/${companyId}/memberships?type=${type}`;
  }
  const response = await httpHelpers.authenticatedGetRequest(url);
  const filteredResponse = response.data.filter((status) => {
    if (status.status === 'DELETED') {
      return false;
    }
    return true;
  });
  return filteredResponse;
}

export async function updateCompanyServiceFeesServices(companyId, reqBody) {
  const url = `/api/companies/${companyId}/service-fees`;
  const response = await httpHelpers.authenticatedPutRequest(url, reqBody);
  return response;
}

export async function companiesPayLaterService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/pay-later-configuration`;
  const response = await httpHelpers.authenticatedPutRequest(url, reqBody);
  return response;
}

export async function createBillingService(companyId, billing) {
  const url = `/api/companies/${companyId}/billing-setup`;
  const reqBody = {
    ...billing,
  };
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function enableProductService(
  companyId, productType, findOutMore, commercialAgreement,
) {
  const url = `/api/companies/${companyId}/products`;
  const reqBody = {
    productType,
    findOutMore,
    commercialAgreement,
  };
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function updateCompanyProductsService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/products`;
  const response = await httpHelpers.authenticatedPutRequest(url, reqBody);
  return response;
}

export async function getCompanyMediaService(companyId) {
  const url = `/api/companies/${companyId}/media`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function updateCompanyMedia(companyId, values, headers) {
  if (!values) {
    throw new Error('No image provided');
  }
  const reqBody = new FormData();
  if (values.logo) {
    reqBody.append('logo', values.logo);
  }
  if (values.cover) {
    reqBody.append('cover', values.cover);
  }
  const url = `/api/companies/${companyId}/media`;
  return axios.post(url, reqBody, {
    headers,
  });
}

export async function updateCompanyResourceMedia(companyId, values, headers) {
  if (!values) {
    throw new Error('No image provided');
  }
  const reqBody = new FormData();

  if (values.image) {
    reqBody.append('image', values.image);
    reqBody.append('type', values.type);
    reqBody.append('resourceId', values.resourceId);
  }
  const url = `/api/companies/${companyId}/resource-media`;
  return axios.post(url, reqBody, {
    headers,
  });
}

export async function updateBillingService(companyId, billingId, reqBody) {
  const url = `/api/companies/${companyId}/billing/${billingId}`;
  const response = await httpHelpers.authenticatedPutRequest(url, reqBody);
  return response;
}

export async function getCompanyUsersService(userId) {
  const url = `/api/users/${userId}/companies`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function getCompanyInvoicesService(companyId, status, selectedMonth) {
  const url = `/api/companies/${companyId}/invoices?status=${status}&selectedMonth=${selectedMonth}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function getInvoiceInformationService(companyId, invoiceId) {
  const url = `/api/companies/${companyId}/invoice/${invoiceId}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function resendInvoiceService(companyId, invoiceId, reqBody) {
  const url = `/api/companies/${companyId}/send-invoice/${invoiceId}`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function updateInvoiceInstructionsService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/invoices-instructions`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function createCompanyInvoicesService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/invoices`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function voidInvoiceService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/void-invoice`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function recordPaymentInvoicesService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/record-payment-invoices`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function updateInvoiceService(companyId, invoiceId, reqBody) {
  const url = `/api/companies/${companyId}/invoices/${invoiceId}`;
  const response = await httpHelpers.authenticatedPutRequest(url, reqBody);
  return response;
}

export async function DownloadInvoiceService(companyId, invoiceId, reqBody) {
  const url = `/api/companies/${companyId}/download-invoice/${invoiceId}`;
  const response = await httpHelpers.authenticatedGetRequest(url, reqBody);
  return response;
}

export async function createCompanyBookingModifiersService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/booking-modifiers`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  const filteredResponse = response.data.filter((status) => {
    if (status.status === 'DELETED') {
      return false;
    }
    return true;
  });
  return filteredResponse;
}

export async function getCompanyInfoServices(
  companyId, onboarding = false, showAccountOwner = false,
) {
  const url = `/api/companies/${companyId}`;
  if (onboarding || showAccountOwner) {
    return httpHelpers.authenticatedGetRequest(`${url}?onboarding=${onboarding}&showAccountOwner=${showAccountOwner}`);
  }

  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function getApplicationFeesService(companyId, startDate = null, endDate = null) {
  const url = `/api/companies/${companyId}/application-fees`;

  const reqBody = {
    ...(startDate && { startDate }),
    ...(endDate && { endDate }),
  };

  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function changeCompanyPaymentPreferenceServices(companyId, reqBody) {
  const url = `/api/companies/${companyId}/payments`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function createCompanyMembershipService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/memberships`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function authoriseStripeService(companyId, stripeAuthorisationCode) {
  const body = { stripeAuthorisationCode };
  const url = `/api/payments/stripe/authorise/${companyId}`;
  const response = await httpHelpers.authenticatedPostRequest(url, body);

  return response;
}

export async function updateCompanyServices(companyId, reqBody) {
  const url = `/api/companies/${companyId}`;
  const response = await httpHelpers.authenticatedPutRequest(url, reqBody);
  return response;
}
export async function updateCompanyDetailsService(companyId, reqBody) {
  const url = `/api/companies/${companyId}`;
  const response = await httpHelpers.authenticatedPutRequest(url, reqBody);
  return response;
}

export async function getCompanySitesService(companyId) {
  const url = `/api/companies/${companyId}/sites`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function retrieveBillingService(companyId, billingId) {
  const url = `/api/companies/${companyId}/billing/${billingId}`;
  const body = { billingId };
  const response = await httpHelpers.authenticatedGetRequest(url, body);
  return response;
}

export async function setBillingAsProcessingService(companyId, billingId) {
  const url = `/api/companies/${companyId}/billing/${billingId}`;
  const body = { status: 'PROCESSING' };
  const response = await httpHelpers.authenticatedPutRequest(url, body);
  return response;
}

export async function createStripeBillingService(companyId, source) {
  const url = `/api/companies/${companyId}/billing`;
  const body = { source };
  const response = await httpHelpers.authenticatedPostRequest(url, body);

  if (response.status === 201) {
    return response;
  }
  return response;
}

export async function runDbHealthCheckService(companyId, otherParams) {
  const url = `/api/companies/${companyId}/run-db-health-check`;
  const response = await httpHelpers.authenticatedGetRequest(`${url}?${new URLSearchParams(otherParams).toString()}`);
  return response;
}

export async function runDoubleBookingCheckService(companyId) {
  const url = `/api/companies/${companyId}/run-double-booking-check`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function createCompanyService(companyId, reqBody, headers) {
  const url = `/api/companies/${companyId}/create-company`;
  return axios.post(url, reqBody, {
    headers,
  });
}

export async function updateCompanyAddress(companyId, reqBody) {
  const url = `/api/companies/${companyId}/updateAddress`;
  const response = await httpHelpers.authenticatedPutRequest(url, reqBody);
  return response;
}

export async function getCompanySocials(companyId) {
  const url = `/api/companies/${companyId}/getCompanySocials`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function updateCompanySocials(companyId, reqBody) {
  const url = `/api/companies/${companyId}/updateCompanySocials`;
  const response = await httpHelpers.authenticatedPutRequest(url, reqBody);
  return response;
}

export async function getDevices(companyId, params) {
  let url = `/api/companies/${companyId}/devices`;
  if (params) {
    url += `?${new URLSearchParams(params).toString()}`;
  }
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function getDevicesOverrides(companyId, selectedDate) {
  const url = `/api/companies/${companyId}/devices-overrides?selectedDate=${selectedDate}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function addDevice(companyId, reqBody) {
  const url = `/api/companies/${companyId}/devices`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function updateDeviceStatus(companyId, deviceId, reqBody) {
  const url = `/api/companies/${companyId}/devices/${deviceId}`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function sendDeviceCommand(companyId, deviceNodeId, reqBody) {
  const url = `/api/companies/${companyId}/devices/${deviceNodeId}/command`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function sendBulkDeviceCommand(companyId, reqBody) {
  const url = `/api/companies/${companyId}/devices/command`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function sendNodeCommand(companyId, nodeId, command, options) {
  const url = `/api/companies/${companyId}/devices/node/${nodeId}/command`;
  const response = await httpHelpers.authenticatedPostRequest(url, { command, options });
  return response;
}

export async function sendBulkNodeCommand(companyId, reqBody) {
  const url = `/api/companies/${companyId}/devices/node/command`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function updateDeviceNode(companyId, nodeId, reqBody) {
  const url = `/api/companies/${companyId}/devices/node/${nodeId}`;
  const response = await httpHelpers.authenticatedPutRequest(url, reqBody);
  return response;
}

export async function initialiseLocks(companyId) {
  const url = `/api/companies/${companyId}/devices/initialise/locks`;
  const response = await httpHelpers.authenticatedPostRequest(url);
  return response;
}

export async function bulkUserInvite(companyId, values, headers) {
  if (!values) {
    throw new Error('No file provided');
  }
  const reqBody = new FormData();

  if (values.users) {
    reqBody.append('users', values.users);
  }

  const url = `/api/companies/${companyId}/bulk-invite-user`;

  return axios.post(url, reqBody, {
    headers,
  });
}

export async function archiveUsers(companyId, users) {
  const url = `/api/companies/${companyId}/users/archive`;
  const response = await httpHelpers.authenticatedPostRequest(url, { users });
  return response;
}

export async function getCompanies(query) {
  const url = '/api/companies';
  const searchParams = new URLSearchParams(query);
  const response = await httpHelpers.authenticatedGetRequest(`${url}?${searchParams.toString()}`);
  return response;
}

export async function verifyPendingCompany(companyId, data) {
  const url = `/api/companies/${companyId}/verify`;
  const response = await httpHelpers.authenticatedPostRequest(url, data);
  return response;
}

export async function initDevice(companyId, data) {
  console.log('initDevice', data);

  const url = `/api/companies/${companyId}/devices/init`;
  const response = await httpHelpers.authenticatedPostRequest(url, data);
  return response;
}

export async function createXeroCompany(company) {
  const url = `/api/companies/${company.id}/create-xero-company`;
  const response = await httpHelpers.authenticatedPostRequest(url, company);
  return response;
}

export async function getCompanyFacility(companyId, facilityId) {
  const url = `/api/companies/${companyId}/facilities/${facilityId}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function trackCompanyBilling(companyId, data) {
  const url = `/api/companies/${companyId}/billing/track`;
  const response = await httpHelpers.authenticatedPostRequest(url, data);
  return response;
}
